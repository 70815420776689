.wrapper{
    margin-top: 20px;
    display: block;
    text-align: left;
    width: 682px;
    height: 110px;
    border: 3px solid #818181;
    border-radius: 20px;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 18px;
}

.wrapper_link{
    text-decoration: none;
}

.description{

    vertical-align: top;
    min-height: 106px;
    width: 66%;
    display: inline-block;

    /* position: relative;
    top:-40px;


    height: 50px; */
}

.title{
    padding-left: 10px;
    padding-top: 6px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: #818181;
}

.text{
    margin-top: 5px;
    padding-left: 10px;

    font-family: 'Montserrat';
    font-style: normal;



    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    color: #000000;
}

.img{
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 34%;
    height: 100%;

    vertical-align: middle;

}

.img_logo{
    display: inline-block;
    vertical-align: middle;
    width: 220px;
    height: 100%;
    margin-left: 10px;
    /* margin-left: 3px; */
    border-radius: 17px;


}
.category_img{
    width:56px;
    position: relative;
    top:-58px;
    left:171px;
}

.link {
    display: inline-block;
    position: relative;
    top:-45px;
    left:50%;
    width: 100px;
    height: 30px;
    text-decoration: none;
    background: #FF0000;
    border-radius: 20px;
}

.linkText{

    display: inline-block;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    align-content: center;
    text-align: center;
    color: #FFFFFF;
    width:100px ;
    padding-top: 5px;

}

@media (max-width: 1480px) {


    .wrapper{
        width: 770px;
        height: 106px;
        margin: auto;
    }
    .description{
        width: 70%;
    }
    .img{
        /* display: inline-block;
        -moz-box-sizing: border-box; 
        -webkit-box-sizing: border-box; 
        box-sizing: border-box; */
        width: 30%;
        text-align: right;
    }

    .link {
        display: block;
        position: relative;
        top:-50px;
        left:60%;

    }
    .img_logo{
        position: relative;
        top:-1px;
        right: -1px;
        width: 201px;
        height: 102px;
    }

    .category_img{
        width:56px;
        position: relative;
        top:-59px;
        left:-2px;
    }
}

@media (max-width: 830px) {

    .wrapper{
        width: 650px;
        height: 110px;
    }

    .category_img{
        width:56px;
        position: relative;
        top:-59px;
        left:-2px;
    }



    .link {
        display: block;
        position: relative;
        top:-40px;
        text-align: right;
        left:52%;

    }

    .img{
        display: inline-block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 30%;
        text-align: right;

    }

    .img_logo{

        position: relative;
        /* top:1px; */
        left: -17px;

        height: 105px;

    }
}

@media (max-width: 765px) {
    .title{
        display: block;
        width: 90%;
        text-align: left;
    }
    .wrapper{
        position: static;
        display: block;
        height: auto;
        min-height: 240px;
        max-width: 620px;
        width: 100%;
    }


    .description{
        position: static;
        width: 95%;
        display: block;
        min-height: 20px;
        margin: 10px 20px;

    }
    .img{
        position: static;
        width: 50%;
        display: block;
        margin: auto;

        text-align: right;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        /*margin-bottom: -80px;*/
    }

    .img_logo{

        width: 100%;
        height: 100%;

        border-radius: 17px;
        margin-left: 18px;
    }

    .category_img{
        width:45px;

        position: relative;
        top:-105px;
        left:-3px;
        margin-bottom: -100px;
    }

    .link {
        display: block;
        position: static;
        margin: 10px auto;

    }
}

@media (max-width: 390px) {
    .title{
        display: block;
        width: 90%;
        text-align: left;
        font-size: 18px;
    }
    .description{
        position: static;
        width: 90%;

    }

    .wrapper{

        min-height: 190px;

    }
    .text{
        width: 90%;
    }
}
 
