.wrapper{
    margin: 15px;
}
.text_ansver_box{
    width: 400px;
}
.text_box{
    width: 400px;
}

.imgQuiz{
    width: 300px;
    margin: 10px;
}

.imgQuiz img{
    width: 100%;
}

.text_ansver_box{
    width: 600px;
}
.text_box{
    width: 600px;
}