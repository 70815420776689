.card_wrapper{
    height: auto;
}
.wrapper{
    height: auto;
    margin: 10px auto;
    min-width: 300px;
    width: 60%;
}

.card{
    border:1px solid blue;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 10px;
    height: auto;
    background-color: #e4edfc6b;
}

.worker_information{
   padding: 10px 15px;
    background-color: rgba(192, 192, 192, 0.18);
    border-radius: 20px;
}

.message{
    color:red;
}
.title_card{
    margin: 10px 0 3px 0;
}

.btn_del{
    display: inline-block;
    width: 120px;
    margin-bottom:10px;
    border:1px solid red;
    border-radius: 20px;
    background-color: rgb(255, 193, 193);
    padding: 5px;
   cursor: pointer;
}

.btn_update{
    display: inline-block;
    width: 120px;
    margin-bottom:10px;
    border:1px solid orange;
    border-radius: 20px;
    background-color: rgb(255, 225, 170);
    padding: 5px;
    cursor: pointer;
    margin-right: 40px;
}

.backButton{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}

.textbox_data_post{
    width: 95%;
    padding: 5px;
    font-size: 16px;
    margin: auto;
}

.text_post{
   
    width: 95%;
    padding: 5px;
    font-size: 16px;
}

.wrapper_checkbox{
    margin: 10px;
}

.wrapper_checkbox input{
    padding:10px;
    width:19px;
     height:19px;
}