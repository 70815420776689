.wrapper{
    margin-top: 80px;
    font-family: Gilroy;
    font-weight: 800;

}
.title{
text-align: center;
    font-size: 36px;
    color:#FF0000;
    margin-bottom: 10px;
}
.t_title{
    font-size: 32px;
    font-weight: 300;
    color: #ff0000
}
.t_score{
    font-size: 22px;
    text-align: center;
    margin: auto;

}
.tr_score_finish td{
    color: #383737
}
.t_score tr{
    border: 3px solid #FF0000;
}
.t_score td{
    padding: 2px 10px;
}