body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.modal_background{

}
.asyncImage_loadImg__3yIQ3{
    width: 39%;
}
.quizchoice_wrapper__3zx3S{
    margin-top: 20px;
    display: block;
    text-align: left;
    width: 682px;
    height: 110px;
    border: 3px solid #818181;
    border-radius: 20px;
    box-sizing: border-box;
    margin-right: 18px;
}

.quizchoice_wrapper_link__7x7x9{
    text-decoration: none;
}

.quizchoice_description__3RSjR{

    vertical-align: top;
    min-height: 106px;
    width: 66%;
    display: inline-block;

    /* position: relative;
    top:-40px;


    height: 50px; */
}

.quizchoice_title__2HRy2{
    padding-left: 10px;
    padding-top: 6px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: #818181;
}

.quizchoice_text__1qw8F{
    margin-top: 5px;
    padding-left: 10px;

    font-family: 'Montserrat';
    font-style: normal;



    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    color: #000000;
}

.quizchoice_img__2lvKK{
    display: inline-block;
    box-sizing: border-box;
    width: 34%;
    height: 100%;

    vertical-align: middle;

}

.quizchoice_img_logo__1CnhP{
    display: inline-block;
    vertical-align: middle;
    width: 220px;
    height: 100%;
    margin-left: 10px;
    /* margin-left: 3px; */
    border-radius: 17px;


}
.quizchoice_category_img__33Z0Y{
    width:56px;
    position: relative;
    top:-58px;
    left:171px;
}

.quizchoice_link__1ZOr3 {
    display: inline-block;
    position: relative;
    top:-45px;
    left:50%;
    width: 100px;
    height: 30px;
    text-decoration: none;
    background: #FF0000;
    border-radius: 20px;
}

.quizchoice_linkText__17jO1{

    display: inline-block;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    align-content: center;
    text-align: center;
    color: #FFFFFF;
    width:100px ;
    padding-top: 5px;

}

@media (max-width: 1480px) {


    .quizchoice_wrapper__3zx3S{
        width: 770px;
        height: 106px;
        margin: auto;
    }
    .quizchoice_description__3RSjR{
        width: 70%;
    }
    .quizchoice_img__2lvKK{
        /* display: inline-block;
        -moz-box-sizing: border-box; 
        -webkit-box-sizing: border-box; 
        box-sizing: border-box; */
        width: 30%;
        text-align: right;
    }

    .quizchoice_link__1ZOr3 {
        display: block;
        position: relative;
        top:-50px;
        left:60%;

    }
    .quizchoice_img_logo__1CnhP{
        position: relative;
        top:-1px;
        right: -1px;
        width: 201px;
        height: 102px;
    }

    .quizchoice_category_img__33Z0Y{
        width:56px;
        position: relative;
        top:-59px;
        left:-2px;
    }
}

@media (max-width: 830px) {

    .quizchoice_wrapper__3zx3S{
        width: 650px;
        height: 110px;
    }

    .quizchoice_category_img__33Z0Y{
        width:56px;
        position: relative;
        top:-59px;
        left:-2px;
    }



    .quizchoice_link__1ZOr3 {
        display: block;
        position: relative;
        top:-40px;
        text-align: right;
        left:52%;

    }

    .quizchoice_img__2lvKK{
        display: inline-block;
        box-sizing: border-box;
        width: 30%;
        text-align: right;

    }

    .quizchoice_img_logo__1CnhP{

        position: relative;
        /* top:1px; */
        left: -17px;

        height: 105px;

    }
}

@media (max-width: 765px) {
    .quizchoice_title__2HRy2{
        display: block;
        width: 90%;
        text-align: left;
    }
    .quizchoice_wrapper__3zx3S{
        position: static;
        display: block;
        height: auto;
        min-height: 240px;
        max-width: 620px;
        width: 100%;
    }


    .quizchoice_description__3RSjR{
        position: static;
        width: 95%;
        display: block;
        min-height: 20px;
        margin: 10px 20px;

    }
    .quizchoice_img__2lvKK{
        position: static;
        width: 50%;
        display: block;
        margin: auto;

        text-align: right;
        box-sizing: border-box;
        /*margin-bottom: -80px;*/
    }

    .quizchoice_img_logo__1CnhP{

        width: 100%;
        height: 100%;

        border-radius: 17px;
        margin-left: 18px;
    }

    .quizchoice_category_img__33Z0Y{
        width:45px;

        position: relative;
        top:-105px;
        left:-3px;
        margin-bottom: -100px;
    }

    .quizchoice_link__1ZOr3 {
        display: block;
        position: static;
        margin: 10px auto;

    }
}

@media (max-width: 390px) {
    .quizchoice_title__2HRy2{
        display: block;
        width: 90%;
        text-align: left;
        font-size: 18px;
    }
    .quizchoice_description__3RSjR{
        position: static;
        width: 90%;

    }

    .quizchoice_wrapper__3zx3S{

        min-height: 190px;

    }
    .quizchoice_text__1qw8F{
        width: 90%;
    }
}
 


.navBarChoiceQuiz_wrapper__mPuvn{
    /*position: absolute;*/
    /*width: 1440px;*/
    height: 110px;
    width: 100%;
    background: #FF0000;
    z-index: 10004;
}

.navBarChoiceQuiz_logo__32sjz{
    position: absolute;
    width: 381px;
    height: 68px;
    left: 30px;
    top: 22px;

}

.navBarChoiceQuiz_logo_up__3BWTd{
    position: absolute;
    height: 25px;
    /*left: 32px;*/
   

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24.8px;
    line-height: 25px;
    /* identical to box height */


    color: #fff;

}


.navBarChoiceQuiz_logo_down__2Rv0K{
    position: absolute;
    width: 400px;
    height: 49px;
    /*left: 30px;*/
    top: 18px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */


    color: #fff;

}

.navBarChoiceQuiz_merch_logo__2gN0q{
    display: inline-block;
    float: right;
    padding-right: 30px;
    position: relative;
    top:15px;

}
.navBarChoiceQuiz_learnLogo__3cEJD{
    position: relative;
    top:-30px;
    margin-right: 30px;

}
.navBarChoiceQuiz_product__3jWtH{
    width: 80px;
}
.navBarChoiceQuiz_logo_native__VfWST{
    display: block;
    position: fixed;
    top:10px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    height: 90px;
}
@media (max-width:1000px) {
    .navBarChoiceQuiz_merch_logo__2gN0q{
        display: none;
    }
}
@media (max-width: 700px) {
    .navBarChoiceQuiz_logo__32sjz{

        left: 15px;
        top: 22px;

    }
}

@media (max-width: 500px) {
    .navBarChoiceQuiz_logo_up__3BWTd{
        font-size: 18.6px;
        left: 0;
        width: 305px;
    }
    .navBarChoiceQuiz_logo_down__2Rv0K{
        top: 15px;
        left:0;
        font-size: 30px;
        width: 305px;
    }
    .navBarChoiceQuiz_logo__32sjz{
        margin-top: 5px;
        width: 200px;
    }
}

@media (max-width: 340px) {
    .navBarChoiceQuiz_logo_up__3BWTd{
        font-size: 13.1px;
        left: 15px;

    }
    .navBarChoiceQuiz_logo_down__2Rv0K{
        top: 4px;
        left: 15px;
        font-size: 21px;
        width: 200px;
    }
    .navBarChoiceQuiz_logo__32sjz{
        width: 200px;
        margin-top: 10px;
    }
}



.navBarChoiceQuestion_wrapper__1w7mp{
    /*position: absolute;*/
    /*width: 1440px;*/
    height: 110px;
    width: 100%;
    background: #FF0000;
}

.navBarChoiceQuestion_logo__3BQci{
    position: absolute;
    width: 381px;
    height: 68px;

    top: 22px;
    left: 32px;
}
.navBarChoiceQuestion_logo_native__2bqPc{
    display: block;
    position: fixed;
    top:10px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    height: 90px;
}
.navBarChoiceQuestion_logo_up__1nq6P{
    position: absolute;

    height: 25px;
    
   

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24.8px;
    line-height: 25px;
    /* identical to box height */


    color: #fff;

}


.navBarChoiceQuestion_logo_down__nsy0b{
    position: absolute;
    width: 500px;
    height: 49px;
    
    top: 15px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */


    color: #fff;

}

.navBarChoiceQuestion_poin_back__2HgDi{
   position:absolute;
   right: 20px;
   top:45px;
    width: 300px;
    height: 30px;
    text-align: center;
    border: 3px solid #fff;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;

    cursor: pointer;
}


@media (max-width: 770px){
    .navBarChoiceQuestion_poin_back__2HgDi{
        display: block;
        position: relative;
        top:130px;
        left: 1%;
        /*left: 7px;*/
        width: 290px;
        border: 3px solid #ff0000;
        margin: 0 auto;
        color:#ff0000;

    }
}


/*@media (max-width: 480px) {*/
/*    .logo_up{*/
/*        font-size: 20px;*/
/*    }*/
/*    .logo_down{*/
/*        top: 36px;*/
/*        font-size: 30px;*/
/*    }*/
/*}*/

@media (max-width: 380px) {
    .navBarChoiceQuestion_logo_up__1nq6P{
        font-size: 10px;
    }
    .navBarChoiceQuestion_logo_down__nsy0b{
        top: 28px;
        font-size: 20px;
    }
}



@media (max-width: 500px) {
    .navBarChoiceQuestion_logo_up__1nq6P{
        font-size: 18.7px;
        
        width: 305px;
    }
    .navBarChoiceQuestion_logo_down__nsy0b{
        top: 15px;
        
        font-size: 30px;
        width: 305px;
    }
    .navBarChoiceQuestion_logo__3BQci{
        width: 200px;
        margin-top: 5px;
    }
}

@media (max-width: 340px) {
    .navBarChoiceQuestion_logo_up__1nq6P{
        font-size: 13.2px;
        left: 15px;

    }
    .navBarChoiceQuestion_logo_down__nsy0b{
        top: 4px;
        left: 15px;
        font-size: 21px;
        width: 200px;
    }
    .navBarChoiceQuestion_logo__3BQci{
        width: 200px;
        margin-top: 10px;
    }
}

@media (max-width: 700px) {
    .navBarChoiceQuestion_logo__3BQci{

        left: 15px;
        top: 22px;

    }
}



.loader_loaderElement__2OyoS{
    width: 200px; /*задаем ширину блока*/
    height:200px; /*задаем высоту блока*/
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;

}
/* .wrapepr{
    min-height: calc(100vh - 170px) ;
} */
.choicehQuizPage_wrapper__1fvZO{
    width: 100%;
    height: auto;

}
.choicehQuizPage_start_build__1LXk7{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #FF0000;
    opacity: 0.1;
    position: fixed;
    bottom:10px;
    right: 10px;
    cursor: pointer;
    z-index: 100;
}
.choicehQuizPage_active_category__2_-uM{
    padding: 10px;
    color: #215cae;
    background-color: #7eccf7;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin: 20px 17px;
    cursor: pointer;
}

.choicehQuizPage_category_name__2NNdV{
    padding: 10px;
    color: #285CA6;
    background-color: #A2D9F7;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin: 20px 15px;
    cursor: pointer;
}

.choicehQuizPage_arrow__2ANYu{

    float:right;
    padding-right: 10px;
}

.choicehQuizPage_wrapper_quiz_blocks__3nPtV{

    max-width:1420px;
    height: auto;
    min-height: 110px;
    margin: 0 auto;
    padding-bottom: 10px;
    text-align: center
}
.choicehQuizPage_count_wrapper_active__VaGyQ,
.choicehQuizPage_count_wrapper__24a75{
    margin-top: -57px;
    margin-left:380px;
    width: 470px;
    position: relative;
    top:-5px;
    text-align: left;
    height: 30px;
}

.choicehQuizPage_task_score__2w6wl{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    vertical-align: middle;

    color: #000000;
}

.choicehQuizPage_wrapper_count_team_add__1pice{
    display: inline-block;
    margin-left: 10px;
    width: 103px;
    height: 28px;
    vertical-align: middle;
    text-align: center;


    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 5px;
}

.choicehQuizPage_count_team_label_data__20ggp{
    display: inline-block;
    margin: 3px 10px;
}

.choicehQuizPage_count_team_label__3fOqE{

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}

.choicehQuizPage_count_team_label_add__6Nnk-{
    display: inline-block;
    text-align: center;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;

    cursor: pointer;

    color: #000000;

    width: 20px;
    height: 20px;
    left: 816px;
    top: 150px;

    background: rgba(255, 255, 255, 0.81);
    border: 1px solid #FF0000;
    box-sizing: border-box;
    border-radius: 5px;
}

.choicehQuizPage_task_score_checkbox__1s5NR{
    font-size: 16px;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    vertical-align: middle;
}
.choicehQuizPage_wrapper_count_team_no_active__1QAmu,
.choicehQuizPage_wrapper_count_team__2M1Y7{
    display: inline-block;
    position: relative;
    top:2px
}
.choicehQuizPage_wrapper_count_team_no_active__1QAmu{
    opacity: 0; /* Значение прозрачности */
    filter: alpha(Opacity=0); /* Прозрачность в IE */
}
.choicehQuizPage_searchTextBoxActive__29ekM,
.choicehQuizPage_searchTextBox__r2i6j{
    display: block;
    margin: 30px 30px 30px 10px;
    width: 340px;
    height: 40px;
    left: 30px;
    top: 140px;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 10px;
}
/* https://xn--80adsajtfqq.xn--c1abdmxeng9ge.xn--p1ai/quiz/623acfd275188a4016072deb */

.choicehQuizPage_row_quiz__2tfsr{
    display: inline-block;
    vertical-align: middle;
    /* width: 96%; */
    /* margin: -50px auto; */
    padding: 10px 0;
    /* padding-right: 30px; */
    text-align: center;
    height: auto;
    min-height: 110px;
    font-size: 0;

}

.choicehQuizPage_quiz_empty__3DsCZ{

}

@media (max-width: 1480px) {
    .choicehQuizPage_wrapper_quiz_blocks__3nPtV{

        max-width: 795px;
    }
    .choicehQuizPage_searchTextBoxActive__29ekM,
    .choicehQuizPage_searchTextBox__r2i6j{
        display: block;
        margin: 30px 30px 30px 10px;
        width: 300px;
    }
    .choicehQuizPage_count_wrapper_active__VaGyQ,
    .choicehQuizPage_count_wrapper__24a75{
        margin-top: -47px;
        margin-left:324px;
        width: 460px;
        position: relative;
        top:-17px;
        text-align: left;
        height: 30px;
    }

}

@media(max-width:830px){
    .choicehQuizPage_wrapper_quiz_blocks__3nPtV{

        max-width: 795px;

    }

    .choicehQuizPage_wrapper_quiz_blocks__3nPtV{
        max-width: 655px;
    }

    .choicehQuizPage_searchTextBoxActive__29ekM,
    .choicehQuizPage_searchTextBox__r2i6j{
        display: block;
        margin: 30px 30px 55px 3px;

    }

    .choicehQuizPage_count_wrapper_active__VaGyQ,
    .choicehQuizPage_count_wrapper__24a75{
        position: static;
        height: 30px;
        margin-left: 3px;
    }
    .choicehQuizPage_active_category__2_-uM,
    .choicehQuizPage_category_name__2NNdV{

        margin: 20px auto;
    }

}

@media (max-width: 765px) {

    .choicehQuizPage_active_category__2_-uM,
    .choicehQuizPage_category_name__2NNdV{

        margin: 20px auto;
    }
    .choicehQuizPage_searchTextBoxActive__29ekM,
    .choicehQuizPage_searchTextBox__r2i6j{
        display: block;
        margin: 30px 30px 55px 15px;
    }

    .choicehQuizPage_count_wrapper_active__VaGyQ,
    .choicehQuizPage_count_wrapper__24a75{
        position: static;
        width: 95%;
        height: 30px;
        margin-left: 17px;
    }

    .choicehQuizPage_row_quiz__2tfsr{
        display: block;
        width: 96%;
        margin: auto;
    }

}
@media(max-width:490px){

    .choicehQuizPage_count_wrapper_active__VaGyQ,
    .choicehQuizPage_count_wrapper__24a75{
        position: static;
        margin-top: -40px;
        width: 95%;
        height: 30px;
        margin-left: 17px;
    }
    .choicehQuizPage_wrapper_count_team__2M1Y7{
        margin-right: 10px;
    }

    .choicehQuizPage_searchTextBoxActive__29ekM,
    .choicehQuizPage_searchTextBox__r2i6j{
        width: 260px;
    }
    .choicehQuizPage_count_wrapper_active__VaGyQ{

        margin-bottom: 25px;
    }
}

@media(max-width:400px){
    /*.wrapper_count_team_add{*/
    /*    display:block;*/
    /*    width: 90%;*/
    /*}*/

    .choicehQuizPage_row_quiz__2tfsr{
        display: block;
        width: 96%;
        padding: 10px 0;
        margin: auto;
    }
    .choicehQuizPage_searchTextBoxActive__29ekM,
    .choicehQuizPage_searchTextBox__r2i6j{
        width: 260px;
    }
}
@media(max-width:350px){
    .choicehQuizPage_count_wrapper_active__VaGyQ{
        margin-bottom: 25px;
        height: 60px;
    }

    /*.wrapper_count_team{*/
    /*    display: block;*/
    /*}*/
    .choicehQuizPage_wrapper_count_team__2M1Y7{
        width: 100%;
    }
    .choicehQuizPage_wrapper_count_team_add__1pice{
        display: inline-block;
        position: static;
        width: 95%;
        margin: 10px auto 20px auto;
    }
}
.Description_modal_wrapper__2PFJg{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    background-color: rgba(224, 224, 224, 0.6);
    z-index: 999998;
}
.Description_warning_area__3WY_a{
    border: 3px solid #ff1a1a;
}

.Description_area__3uFJI{
    border:1px solid #A2D9F7;
}

.Description_wrapper__2F_oU{
    overflow-y: auto;
    margin: 130px auto;
    width: 40%;
    background-color:#A2D9F7;
    /*background: #A2D9F7;*/
    border: 1px solid #285CA6;
    border-radius: 20px;
}
.Description_content__2ikrh{
    margin: 20px 20px 15px 20px;
}

.Description_title__-6ZO1{

    padding-top: 6px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: #285CA6;
}

.Description_description_text__3-2vc textarea{
    display: block;
    margin-top: 9px;
    width: calc(100% - 14px);

    padding: 6px 6px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;

}

.Description_btn_save___n7UW{
    text-align: right;
    margin-top: 15px;

}

.Description_btn_save___n7UW button{
    border:1px solid #285CA6;
    background-color: #285CA6;
    font-family: 'Gilroy';
    color: white;
    border-radius: 20px;
    padding: 6px 15px;
    cursor: pointer;
    height: 30px;
}


@media(max-width:800px){
    .Description_title__-6ZO1{


        font-size: 22px;

    }
    .Description_wrapper__2F_oU{

        margin: 110px auto;
        width: 80%;
    }
}



@media(max-width:560px){
    .Description_title__-6ZO1{


        font-size: 18px;
        line-height: 23px;
    }
    .Description_description_text__3-2vc textarea{

        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

    }
    .Description_wrapper__2F_oU{

        margin: 100px auto;
        width: 80%;
    }
}

@media(max-width:460px){
    .Description_title__-6ZO1{
        font-size: 18px;
        line-height: 23px;
    }
    .Description_description_text__3-2vc textarea{

        font-weight: 700;
        font-size: 14px;
        line-height: 18px;

    }
    .Description_wrapper__2F_oU{

        margin: 100px auto;
        width: 75%;
    }
}

.MapPage_wrapper__2GIgz{
    padding: 20px;
}
.MapPage_title__1TV9c{
    color: #ff0000;
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    margin-top: 10px;

}
.MapPage_map_img__nHLvw{
    display: block;
    width:90%;
    max-width: 900px;
    margin: auto;
}
.MapPage_wrapper_timer__24Kiu{
    margin: auto;
    text-align: center;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 800;
}
.MapPage_timer__3YWNf{
    display: inline-block;
    vertical-align: middle;
    border: 3px solid #ff0000;
    border-radius: 20px;
    padding: 5px 15px;
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin: 10px 10px;
}

.MapPage_btn_start_or_stop__3YsPb{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border: 3px solid #ff0000;
    background-color: #ff0000;
    color: #fff;
    border-radius: 20px;
    padding: 5px 15px;
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin: 10px 10px;
}

.MapPage_timer_svg__3xyin{
    vertical-align: middle;
    display: inline-block;

    cursor: pointer;
    border: 3px solid #ff0000;
    background-color: #ff0000;
    color: #fff;
    border-radius: 20px;
    width: 80px;
    padding: 5px;
    height: 30px;
    margin: 10px 10px;
}

.MapPage_result_table__3JYbi {
    background: rgb(244, 244, 244);

    width: 80%;
    max-width: 500px;
    border-collapse: separate;
    border-spacing: 0;
    margin: auto;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    padding: 10px;
    border: 3px solid #ff0000;
    border-radius: 20px;
    /*border-radius: 20px;*/
    /*border: 3px solid #fff;*/
}


.MapPage_result_table__3JYbi tbody tr td {
    /*border: 1px solid #e8e9eb;*/
}


.MapPage_result_table__3JYbi tbody tr:nth-child(2n) {

    background: rgba(221, 227, 250, 0.8);

}
/*.first_element td:first-child {*/
/*    order-left-style: solid;*/
/*    border-top-left-radius: 20px;*/

/*}*/

/*.first_element td:last-child {*/
/*    order-left-style: solid;*/
/*    border-top-right-radius: 20px;*/

/*}*/
/*.last_element td:first-child {*/
/*    order-left-style: solid;*/
/*    border-bottom-left-radius: 20px;*/

/*}*/

/*.last_element td:last-child {*/
/*    order-left-style: solid;*/
/*    border-bottom-right-radius: 20px;*/

/*}*/
.MapPage_result_table__3JYbi tbody tr {
    background: rgba(244, 244, 244, 0.9);

}

.MapPage_result_table__3JYbi tbody tr:hover {
    background: rgba(40, 92, 166, 0.21);
}

@media (max-width: 770px) {
    .MapPage_title__1TV9c{
        padding-top: 40px;
    }

}
.question_wrapper__3qs_1{
    height: auto;

    margin-top: 10px;
    max-width: 1450px;
}

.question_wrapper_img_number_task__1NPGV{
    display: inline-block;
    width: 51px;
    height: 100%;
    vertical-align: middle;
}
.question_wrapper_text_number_task__2-MZy{
    display: inline-block;
    width: calc(100% -  51px);
    vertical-align: middle;
}

.question_wrapper_count__1m0At{
    display: inline-block;
    height: auto;
    padding: 10px;
    margin-top: 10px;
    width: calc(100% - 270px);

}
.question_cardQuestion__1Rffd{
    height: auto;
    width: 100%;
    max-width: 1450px;
    margin: auto;
}
.question_quiz_name__1FrhD{
    height: auto;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 30px;
    color: #FF0000;
}

/* Измененное */
.question_formQuestionWrapper__1g7Nc{
    min-height: 200px;
    width: 100%;
    max-width: 1450px;
    height: auto;
    border: 3px solid #FF0000;
    box-sizing: border-box;
    border-radius: 20px;
    margin: auto;

}

.question_buttonViewAnswerView__2N4V4,
.question_buttonViewAnswer__8kGog{
    width: 100%;
    max-width: 1450px;
    margin: auto;
    height: auto;
    min-height: 60px;
    padding: 13px 29px;
    margin-top: 20px;

    border: 3px solid #FF0000;
    box-sizing: border-box;
    border-radius: 20px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    /*text-decoration-line: underline;*/
    color: #FF0000;
}

.question_buttonViewAnswerView__2N4V4{
    text-decoration: underline;
    cursor: pointer;
}

.question_btn_exit_quiz_no_team__1Py0l{
    display: inline-block;
    text-decoration: none;
    background: #FFFFFF;
    border: 3px solid #FF0000;
    border-radius: 20px;
    width: 180px;
    height: 30px;
    line-height: 30px;
    margin: 10px auto;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;


    text-align: center;

    color: #FF0000;
}
.question_price_line__227FG{
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    text-align: center;
}
.question_wrapper_btns_next__1sqb6{
    margin-top: 10px;
    text-align: center;
}

.question_btn_close_window__3Yoea{
    position: relative;
    float: right;
    top:-3px;
    left: 10px;
    border: none;
    font-size: 28px;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    color: #FF0000;
    margin-bottom:-10px ;
}

.question_wrapper_score__7FPBL{
    display: inline-block;
    margin: 20px 0 15px 5px ;
    width: 235px;
    height: auto;
    min-height: 120px;
    vertical-align: top;

}



.question_btn_exit_quiz_no_team__1Py0l:hover,
.question_btn_exit_quiz__cdppZ:hover,
.question_answer__VR4J2:hover,
.question_buttonViewAnswer__8kGog:hover{
    cursor: pointer;
}

.question_answer__VR4J2{

    text-decoration: none;
    width: 100%;
    min-height: 60px;
    padding: 13px 29px;
    margin-top: 20px;


    border: 3px solid #FF0000;
    box-sizing: border-box;
    border-radius: 20px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: #FF0000;

}

.question_text_answer__2OgZl{

    margin-left: 10px;
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 20px;

    color: #000000;
}
.question_btn_next_question_dop__fdpEn,
.question_btn_next_question__2iBEE{
    display: inline-block;
    background: #FF0000;
    border:1px solid  #FF0000;
    border-radius: 20px;
    width: 221px;
    height: 30px;
    line-height: 30px;
    margin: 30px auto 10px auto;
    transition-duration: 0.6s;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;

    cursor: pointer;
    text-align: center;

    color: #FFFFFF;
}
.question_btn_next_question_dop__fdpEn{
    display: none;
}
.question_btn_score_view__3hAni,
.question_btn_exit_quiz_early__3lAsy,
.question_btn_exit_quiz__cdppZ{
    text-decoration: none;
    background: #FFFFFF;
    border: 3px solid #FF0000;
    border-radius: 20px;
    width: 221px;
    height: 30px;
    line-height: 30px;
    margin: 30px auto 20px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;

    text-align: center;

    color: #FF0000;
    cursor: pointer;
}
.question_btn_score_view__3hAni{
    display: none;
}
.question_btn_exit_quiz_early__3lAsy{
    cursor: pointer;
    margin: 20px auto 0 auto;
}
.question_category_img__2uip0{
    width: 50px;
    height: 50px;
    vertical-align: middle;

}
.question_task_number__iRTQc{

    display: inline-block;
    vertical-align: middle;
}
.question_task_number_wrapper__2LqZ5{
    display: block;
    vertical-align: middle;
    width: 45%;
    padding: 19px 29px 5px 0px;
    margin-bottom: 10px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: #FF0000;
}
.question_question_wrapper__3EDWX{
    width: 95%;
    margin: auto;
}
.question_textQuestionWrapper__4_TR1{
    display: inline-block;
    font-family:'Montserrat';
    width: 60%;
    font-weight: 800;
    font-size: 30px;
    height: 100%;
    vertical-align: top;

    text-align: justify;
    margin-bottom: 10px;
}

.question_img_question_wrapper__3w9jD{

    text-align: center;
    display: inline-block;
    width: 40%;
    margin-bottom: 20px;

    /*padding-right: 10px;*/
}

.question_img_question__BxmdD{
    /*margin-top: -10px auto 5px auto;*/
    float: right;
    display: block;
    width: 95%;

    /* max-width: 700px; */
    text-align: center;
    border-radius: 20px;


}
.question_wrapper_timer__1xY2o{
    margin: 20px auto;


}
.question_timer_warning__2SCAr,
.question_timer__2NZvj{
    display: inline-block;
    font-weight: 800;
    font-size: 20px;
    border:3px solid #FF0000;
    padding: 10px;
    margin-right: 10px;
    border-radius: 20px;
    width: 100px;
    text-align: center;
}
.question_timer_warning__2SCAr{
    color: #800000;
    -webkit-animation: question_shake__1hDgq 1s;
            animation: question_shake__1hDgq 1s;
    /* Когда анимация будет закончена, начните снова */

}
.question_timer_stop__2c4cL{
    color:#FF0000;
    -webkit-animation: question_shake__1hDgq 1s;
            animation: question_shake__1hDgq 1s;
    font-weight: 800;
    font-size: 24px;
    /* Когда анимация будет закончена, начните снова */

    /*animation: blinker  infinite 0.5s linear;*/
}

@media (max-width: 770px) {

    .question_btn_next_question_dop__fdpEn{
        display: block;
        margin: 10px auto;
    }

    .question_wrapper_score__7FPBL{
        overflow-y: auto;
        max-height: 85%;
        display: block;
        position: fixed;
        left: 50%;
        top: 5%;
        /*top:40px;*/
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        z-index: 10006;
        background-color: rgba(239, 239, 239, 0.99);
        max-width: 300px;
        padding: 5px 20px 10px 20px;
        margin: auto;

        border: 3px solid #FF0000;

        border-radius: 20px;

    }

    .question_task_number_wrapper__2LqZ5{
        width: 45%;
        padding: 19px 0 2px 0;
        margin: auto;

    }

    .question_text_answer__2OgZl{
        font-weight: 700;
        font-size: 16px;
    }

    .question_wrapper_count__1m0At{
        display: block;
        height: auto;
        padding: 10px;
        margin: 10px auto 0 auto;
        max-width: 1450px;
        width: 95%;

    }
    .question_background__2kDId{
        top:0;
        left: 0;
        position: fixed;
        width: 100%;
        height:100vh;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 10005;
    }

    .question_wrapper__3qs_1{
        margin-top:20px;
    }
    .question_quiz_name__1FrhD{
        padding-top: 4px;
    }
    .question_textQuestionWrapper__4_TR1{
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        width: 50%;
        text-align: left;
    }
    .question_btn_score_view__3hAni{
        display: inline-block;
    }
}
.question_btn_stop_timer__2noKB,
.question_btn_start_timer__3aODe{

    display: inline-block;
    font-weight: 800;
    font-size: 20px;
    border:3px solid #FF0000;
    background-color: #FF0000;
    color: white;
    padding: 10px;
    margin-right: 10px;
    border-radius: 20px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}
.question_red_line__3aMHY{
    height: 3px;
    background-color: #FF0000;
    margin: 20px auto;
    width: 95%;
}
@media (max-width: 949px) {
    .question_task_number_wrapper__2LqZ5{
        padding: 19px 0 2px 0;
        width: 90%;
        margin: auto;
    }
    .question_textQuestionWrapper__4_TR1{
        display: block;
        width: 90%;
        height: 100%;
        padding: 10px;
        margin: 0 auto;
        text-align: left;
        /* margin-bottom: 10px; */
        /* padding-left: 30px;
        padding-right: 5px; */
    }

    .question_img_question_wrapper__3w9jD{
        display: block;
        margin: 5px auto 10px auto;
        /* margin-bottom: 20px; */
        width: 60%;

    }
    .question_img_question__BxmdD{
        display: block;
        width: 100%;
        min-width: 120px;
        text-align: center;
        float: none;
    }


}


@-webkit-keyframes question_shake__1hDgq {
    0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); color: #800000; }
    10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
    30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
    90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
    /*0% {*/
    /*    opacity: 1;*/
    /*    color: #8b0a0a;*/
    /*    transform: rotate(0deg);*/
    /*}*/
    /*75%,25 {*/
    /*    !*opacity: 0.7;*!*/
    /*    color: #cd0505;*/
    /*    transform: rotate(-10deg);*/
    /*}*/
    /*100% {*/
    /*    opacity: 1;*/
    /*    color: #8b0a0a;*/
    /*    transform: rotate(0deg);*/
    /*}*/
}


@keyframes question_shake__1hDgq {
    0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); color: #800000; }
    10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
    30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
    90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
    /*0% {*/
    /*    opacity: 1;*/
    /*    color: #8b0a0a;*/
    /*    transform: rotate(0deg);*/
    /*}*/
    /*75%,25 {*/
    /*    !*opacity: 0.7;*!*/
    /*    color: #cd0505;*/
    /*    transform: rotate(-10deg);*/
    /*}*/
    /*100% {*/
    /*    opacity: 1;*/
    /*    color: #8b0a0a;*/
    /*    transform: rotate(0deg);*/
    /*}*/
}
.TimerComponent_wrapper__3D4YL{
    display: inline-block;
}
.questionPage_wrapper__1J_Lf{
    width: 96%;
    height: auto;

    margin: 30px auto 0 auto;
}

/* .quiz_name{
    width:80%;
    max-width: 1450;
    margin: auto;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 18px;
    color: #285CA6;
} */

.questionPage_start_text__1IUQT{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 90px;
    line-height: 110px;
    text-align: center;
    width: 95%;
    color: #FF0000;
    margin: 20px auto;
}

.questionPage_btn_start__tVaDq{
    background: #FF0000;
    border-radius: 47px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    height: 50px;
    line-height: 50px;
    /* identical to box height */
    width: 221px;
    text-align: center;
    color: #FFFFFF;
    margin: 40px auto 40px auto;
    cursor: pointer;
}

.questionPage_message_not_question__2zU30{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 39px;
    margin-top: 10px;
    color: #c43b12;
}

.questionPage_bodyAsk__1Td6g{
    height: auto;
    max-width: 1450px;
    margin: auto;
}

@media (max-width: 770px) {
    .questionPage_wrapper__1J_Lf{
        
        margin: 60px auto 0 auto;

    }
    .questionPage_quiz_name__2O6Rx{
        padding-top: 10px;
        text-align: left;
    }
}
@media (max-width: 400px) {

    .questionPage_quiz_name__2O6Rx{
        text-align: center;
    }
}
.ScoreModal_wrapper__1-ND9{
    /*display: block;*/
    /*position: fixed;*/
    /*top:0;*/
    /*left: 0;*/
    /*height: 100vh;*/
    width: 100%;
    /*background-color: rgba(255, 255, 255, 0.6);*/
}

.ScoreModal_content__2IRvI{
    /*overflow-y: auto;*/
    /*height: auto;*/
    /*max-height: calc(100vh - 200px);*/
    margin: 20px auto;
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 5%;*/
    /*top:40px;*/
    width: 90%;
    /*transform: translateX(-50%);*/
    background-color: rgba(255, 255, 255, 0.39);
    /*border:3px solid #2662B3;*/
    border-radius: 20px;
    max-width: 1000px;
    padding: 10px;

}

.ScoreModal_btn_cancel__1GV1I{
    background: #FF0000;
    border: 1px solid #FF0000;
    border-radius: 20px;
    width: 180px;
    height: 30px;
    line-height: 30px;
    margin: 20px auto 10px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    color: #FFFFFF;

}

.ChartsComponent_title__2o6Mo{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */
    text-align: center;
    margin-top: 30px;
    color: #FF0000;
}

.ChartsComponent_t_score__3vIFk{
    margin: 10px auto;
    border-collapse: collapse;

}

.ChartsComponent_t_td_title__3Ez7V{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #000000;

}
.ChartsComponent_t_td_value__1r038,
.ChartsComponent_t_td_team_name__1Z6as{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    padding: 3px 5px;
    color: #000000;

}
.ChartsComponent_t_td_team_name__1Z6as{
    text-align: left;
    width: 200px;
}
.ChartsComponent_t_td_value__1r038{
    text-align: center;
    width: 200px;
}

.ChartsComponent_red_row_table__p1pFW{
    background-color: rgba(255, 0, 0, 0.25);
}

.ChartsComponent_green_row_first__109L2{
    background-color: rgba(99, 255, 43, 0.45);
}

.ChartsComponent_green_row__2dxw6{
    background-color: rgba(99, 255, 43, 0.25);
}
th.ChartsComponent_row__16i9B {
    font-weight: normal;
    color: #039;
    padding: 10px 15px;
}
td {
    color: #669;
    /*border-top: 1px solid #e8edff;*/
    padding: 10px 15px;
}
tr{
    background: #fff;
}
tr:nth-child(2n) {
    background: #e8edff;
}
.ChartsComponent_first_place_svg__3OJr-{
    width: 40px;
    vertical-align: middle;
    margin-left: 5px;

}
.FinalPage_wrapper__3cHp2{
    margin-top: 80px;
    font-family: Gilroy;
    font-weight: 800;

}
.FinalPage_title__igLZQ{
text-align: center;
    font-size: 36px;
    color:#FF0000;
    margin-bottom: 10px;
}
.FinalPage_t_title__2uKfu{
    font-size: 32px;
    font-weight: 300;
    color: #ff0000
}
.FinalPage_t_score__2RT2n{
    font-size: 22px;
    text-align: center;
    margin: auto;

}
.FinalPage_tr_score_finish__15TqB td{
    color: #383737
}
.FinalPage_t_score__2RT2n tr{
    border: 3px solid #FF0000;
}
.FinalPage_t_score__2RT2n td{
    padding: 2px 10px;
}
.authPage_form__3hS_Z{
    min-width: 150px; /*задаем ширину блока*/
    max-width: 300px; /*задаем ширину блока*/

    margin: 0 auto;
    /*transform: translate(-50%, -50%);*/
    /*position: absolute;*/
    /*top: 40%;*/
    /*left: 50%;*/

    padding:40px 30px 40px 30px;
}
.authPage_wrapper__2u1iK{

}

.authPage_title__1BlFD{
    text-align: center;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;

    color: #FF0000;
}

.authPage_text_box_wrapper__2Swv9{
   margin: 20px auto;
   text-align: center;
}

.authPage_text_box__19so5{
    background: #FFFFFF;
    border: 3px solid #FF0000;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 16px;
    width: 300px;
    height: 40px;
    padding-left: 20px;
}

.authPage_button_enter__1cNkt{
    text-decoration: none;
    display: block;
    background: #FF0000;
    border-radius: 20px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    border: 1px solid #FF0000;
    width: 100px;
    height: 30px;   
    color: #FFFFFF;
    text-align: center;
    margin: 10px auto;
}
.authPage_button_enter__1cNkt:hover{
    cursor: pointer;
   
}

.authPage_error_row__1sA33{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: red;
    
    text-align: center;
    margin-left: -100px;
    margin-top: -10px;
}

.authPage_error_row_emty__3DLI5{
    height:20px;
    width: 100px;
    margin-top: -10px;

  
}

@media(max-width:340px){
    .authPage_text_box__19so5{
        width: 180px;
    }
    
}
.qr_style_wrapper_qr_video__1sjop{
    width: 60%;
    margin:1px auto;
}
.qr_style_wrapper_qr_video_error__3B4wC{
    width: 60%;
    margin:1px auto;
    border: 1px solid red;
}
.qr_style_window__K66h-{
    background-color: #61dafb;
}
.choicePage_formChoice__1bX_p{
   
    height:auto; 
    min-height: calc(100vh - 170px);
      
}

.choicePage_ButtonWrapper__2_fhh{

    padding: 20px 40px 20px 40px;
    display: block;
    top:100px;
    width: 400px;
    margin:40px auto 40px auto;
    /* transform: translate(-50%, -50%); */
    background-color: rgba(161, 239, 255, 0.46);
    border:1px solid #285CA6;
    border-radius: 40px;

}

.choicePage_buttonChoice__3_qw2{
    text-align: center;
    color: aliceblue;
    background-color: #285CA6;
    border:1px solid blue;
    border-radius:20px;
    text-decoration: none;
    margin: auto;
    display: block;
    padding: 6px;
    margin: 10px auto;
    min-width: 200px;
    /* margin-top:20px ; */
    min-height: 30px;

}
.addQuiz_wrapper__3vKCu{
    margin: 15px;
}

.addQuiz_column_name__3f9bV{
    width: 100px;
}
.addQuiz_column_name_text__3DXNA{

}

.addQuiz_data__Mv_kf{
    
}

.addQuiz_data_input__13igx{
    width: 300px;
    padding: 5px;
    border:2px solid silver;
    border-radius: 20px;
}

.addQuiz_imgQuiz__19JvV{
    width: 300px;
    margin: 10px;
}

.addQuiz_imgQuiz__19JvV img{
    width: 100%;
}

.addQuestion_wrapper__2G9Xr{
    margin: 15px;
}
.addQuestion_text_ansver_box__vQ29R{
    width: 400px;
}
.addQuestion_text_box__33Zk9{
    width: 400px;
}

.addQuestion_imgQuiz__1swih{
    width: 300px;
    margin: 10px;
}

.addQuestion_imgQuiz__1swih img{
    width: 100%;
}

.addQuestion_text_ansver_box__vQ29R{
    width: 600px;
}
.addQuestion_text_box__33Zk9{
    width: 600px;
}
.modal_modal_wrapper__3u7QE{
  
        position:fixed;
       

        top:0px;
        left:0px;
        width:100vw;
        height:100vh;
        background-color: rgba(193, 193, 193, 0.541);
        overflow-y: scroll;
}

.modal_modal__cmgJ-{
    display: block;
    margin: 40px auto;
    height:auto;
    min-height: 400px;
    width: 90%;
    top:10px;
    left: 2%;
    background-color: #b3cfdff2;
    border:3px solid #5ec1f7; 
    border-radius: 20px;
   
}

.modal_title__P72F4{

background-color: #5ec1f7;
border-top-left-radius: 16px;
border-top-right-radius: 16px;
padding: 20px;
}

.modal_btn_save__3vL53{
    background-color: rgb(135, 230, 135);
    border: 1px solid green;
    border-radius: 10px;
    padding: 6px;
    min-width: 100px;
    margin-left: 15px;
    
}

.modal_btn_close___tDRT{
    background-color: rgb(255, 141, 141);
    border: 1px solid red;
    border-radius: 10px;
    padding: 6px;
    margin-left: 40px;
    min-width: 100px;
    margin-bottom: 10px;
}

.modal_exit_button__2u4to{
    position: absolute;
    top:52px;
    right: 90px;
    color: rgb(252, 251, 251);
}

.modal_exit_button__2u4to:hover,
.modal_btn_save__3vL53:hover,
.modal_btn_close___tDRT:hover{
    cursor: pointer;
}

.viewQuestion_form__29chC{
    max-width: 1000px;
    min-width: 100px;
    margin: 20px auto;
}

.viewQuestion_title__11UHW{
    padding: 3px;
    margin: 5px;
}
.viewQuestion_label_select__3uDUj{
    margin-bottom: 4px;
}

.viewQuestion_select_quiz__2Fx8M{
    display: block; 
font-size: 16px; 
font-family: sans-serif; 
font-weight: 700; 
color: #444; 
line-height: 1.3; 
padding: .6em 1.4em .5em .8em; 
width:300px; 
max-width: 100%; 
box-sizing: border-box; 
margin: 0; 
border: 1px solid #aaa;
 box-shadow: 0 1px 0 1px rgba(0,0,0,.04); 
border-radius: .5em;
 -webkit-appearance: none;
 appearance: none;
 background-color: #fff; 
background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%); 
background-repeat: no-repeat, repeat;
background-position: right .7em top 50%, 0 0;
background-size: .65em auto, 100%; 
}
.viewQuestion_select_quiz__2Fx8M::-ms-expand{ display: none; } 
.viewQuestion_select_quiz__2Fx8M:hover{ border-color: #888; } 
.viewQuestion_select_quiz__2Fx8M:focus{
    border-color: #aaa; 
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring; 
   color: #222;
    outline: none; 
}

.viewQuestion_select_quiz__2Fx8M option{
    font-weight:normal;
    background-position: left .7em top 50%, 0 0; 
    padding: .6em .8em .5em 1.4em; 
}

.viewQuestion_text__36Y7p{
    border-bottom: 1px solid #ffc6c2;
    border-top: 1px solid #bfbfbf;
    padding: 3px;
    margin: 5px;
}

.viewQuestion_noActive__3b1me{
    padding: 3px;
    text-align: center;
    margin: 5px;
    background-color: rgba(255, 206, 206, 0.69);
    width: 200px;
    border: 1px solid red;
    border-radius: 20px;
}

.viewQuestion_addButton__MaD1f{
    height: 35px;
    width: 300px;
    margin: 5px;
    border:1px solid green;
    border-radius:20px;
    background-color: rgb(142, 243, 142);
    text-align: center;
    padding-top: 6px;
}

.viewQuestion_addButton__MaD1f:hover{
    cursor: pointer;
}

.viewQuestion_wrapper__A0k2f{
    border: 1px solid rgba(59, 95, 147, 0.68);
    background-color: rgba(219, 224, 234, 0.44);
    padding: 10px;
    border-radius: 10px;
    margin: 5px;

}

.viewQuestion_select__1rM7f{
    padding: 10px;
    background-color: #eef5fc;
    border-radius: 10px;
}

.viewQuestion_btn_update__3mPcd{
    display: inline-block;
    padding: 5px;
    text-align: center;
    border: 1px solid orange;
    width: 100px;
    background-color: rgb(248, 220, 170);
    border-radius: 20px;
}

.viewQuestion_btn_del__2ysU3{
    display: inline-block;
    padding: 5px;
    text-align: center;
    border: 1px solid red;
    width: 100px;
    background-color: rgb(255, 176, 176);
    border-radius: 20px;
    margin-left: 40px;
    
}

.viewQuestion_btn_del__2ysU3:hover,
.viewQuestion_btn_update__3mPcd:hover{
    cursor: pointer;
}

.viewQuestion_backButton__1s97R{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}


.viewQuestion_backButton__1s97R{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}
.quizList_form__2eFm5{
    border: 1px solid #5787ad;
    padding:10px;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
    background-color: rgba(229, 232, 245, 0.48);
    margin-bottom: 20px;
}
.quizList_description__3xicW{

    border-bottom: 1px solid #c9c9c9;
    border-top: 1px solid  #c9c9c9;
    margin: 10px 5px;
    padding: 10px;
}

.quizList_title__2dnRT{

    margin-left: 10px;
    padding: 5px;
}

.quizList_noActive__164xg{
    margin: 10px 5px;
    padding: 10px;
    background-color: rgba(255, 115, 115, 0.48);
    color: #261212;
}

.quizList_btn_change__3G17A{
    display: inline-block;
    margin-left: 10px;
    border: 1px solid orange;
    border-radius: 20px;
    padding: 5px;
    min-width: 100px;
    text-align: center;
    background-color: rgb(255, 202, 104);
}

.quizList_btn_del__v64kW{
    display: inline-block;
    margin-left: 10px;
    border: 1px solid red;
    border-radius: 20px;
    padding: 5px;
    min-width: 100px;
    text-align: center;
    background-color: rgb(254, 128, 128);
    margin-left:40px;
}

.quizList_btn_change__3G17A:hover,
.quizList_btn_del__v64kW:hover
{
    cursor: pointer;
}
.viewQuiz_form__9tXUH{
    min-width: 400px;
    max-width: 1024px;
    margin: 5px auto;
    padding: 20px;
    background-color: rgba(241, 241, 241, 0.57);
}

.viewQuiz_addButton__1Mri6{
    width: 220px;
    margin-top: 20px;
    background-color: rgb(62, 211, 62);
    border: 2px solid green;
    border-radius: 20px;
    padding: 5px;
    
}

.viewQuiz_backButton__1SaqX{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}
.App_app__1B0jJ{
   width: 100%;
   height: auto; 
   min-height: calc(100vh - 61px) ;
    padding-bottom: 20px;
   /* height: 700px; */
   /*background-color:#E5E5E5;*/
   /*background-color:rgba(40, 92, 166, 0.1);*/

}
.App_back_ground__2BbNh{
       position: fixed;
      bottom: 0;
      min-width: 100%;
      height: 100vh;
      background: rgb(255, 255, 255);
      z-index:-11;

}
/*.backColor{*/
/*   position: fixed; */
/*  bottom: 0;*/
/*  min-width: 100%;*/
/*  height: 100%;*/
/*  background: rgba(40, 92, 166, 0.1);*/
/*  z-index:-100*/

/*}*/
.App_logoBack__1RT-j{
  position: fixed;
  top: 120px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 70%;
  /* width: 70%; */
  padding: 3px 10px;
  white-space: nowrap;
    opacity: 0.7;
  z-index:-10;
}


@media(max-width:800px){
  .App_app__1B0jJ{
    min-height: calc(100vh - 45px) ;
  }
}


/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 10:05 PM */

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Light.1de7f049.eot);
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url(/static/media/Montserrat-Light.1de7f049.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Light.4fff33ac.woff) format('woff'),
		url(/static/media/Montserrat-Light.ba044963.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-MediumItalic.b3ddbb16.eot);
	src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
		url(/static/media/Montserrat-MediumItalic.b3ddbb16.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-MediumItalic.ad732b72.woff) format('woff'),
		url(/static/media/Montserrat-MediumItalic.5c7f9907.ttf) format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Thin.143850af.eot);
	src: local('Montserrat Thin'), local('Montserrat-Thin'),
		url(/static/media/Montserrat-Thin.143850af.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Thin.78770905.woff) format('woff'),
		url(/static/media/Montserrat-Thin.2597559f.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-ExtraLightItalic.43d12d16.eot);
	src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
		url(/static/media/Montserrat-ExtraLightItalic.43d12d16.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-ExtraLightItalic.d743ecc0.woff) format('woff'),
		url(/static/media/Montserrat-ExtraLightItalic.cf9a7578.ttf) format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-BoldItalic.b8f5b73d.eot);
	src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
		url(/static/media/Montserrat-BoldItalic.b8f5b73d.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-BoldItalic.6fa63549.woff) format('woff'),
		url(/static/media/Montserrat-BoldItalic.45014625.ttf) format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-SemiBold.c139ffb2.eot);
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url(/static/media/Montserrat-SemiBold.c139ffb2.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-SemiBold.3d2d1385.woff) format('woff'),
		url(/static/media/Montserrat-SemiBold.aebf4fe1.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-ExtraLight.117eff1f.eot);
	src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
		url(/static/media/Montserrat-ExtraLight.117eff1f.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-ExtraLight.f555c75a.woff) format('woff'),
		url(/static/media/Montserrat-ExtraLight.490fa183.ttf) format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-ExtraBoldItalic.5ee3b1a7.eot);
	src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
		url(/static/media/Montserrat-ExtraBoldItalic.5ee3b1a7.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-ExtraBoldItalic.e406133e.woff) format('woff'),
		url(/static/media/Montserrat-ExtraBoldItalic.428f1389.ttf) format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Italic.92f84d8b.eot);
	src: local('Montserrat Italic'), local('Montserrat-Italic'),
		url(/static/media/Montserrat-Italic.92f84d8b.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Italic.74f69ef5.woff) format('woff'),
		url(/static/media/Montserrat-Italic.c47cfa55.ttf) format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Bold.a361f0cc.eot);
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url(/static/media/Montserrat-Bold.a361f0cc.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Bold.a79b68fc.woff) format('woff'),
		url(/static/media/Montserrat-Bold.bf2c26d2.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-LightItalic.2e1e2b59.eot);
	src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
		url(/static/media/Montserrat-LightItalic.2e1e2b59.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-LightItalic.bc4d475c.woff) format('woff'),
		url(/static/media/Montserrat-LightItalic.dadd3c3b.ttf) format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-BlackItalic.e70885e2.eot);
	src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
		url(/static/media/Montserrat-BlackItalic.e70885e2.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-BlackItalic.87d5d270.woff) format('woff'),
		url(/static/media/Montserrat-BlackItalic.f9d1621e.ttf) format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-SemiBoldItalic.70008e33.eot);
	src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
		url(/static/media/Montserrat-SemiBoldItalic.70008e33.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-SemiBoldItalic.ac031a32.woff) format('woff'),
		url(/static/media/Montserrat-SemiBoldItalic.74fbdb73.ttf) format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Regular.2dd0a1de.eot);
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url(/static/media/Montserrat-Regular.2dd0a1de.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Regular.de4a911e.woff) format('woff'),
		url(/static/media/Montserrat-Regular.08a754d1.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Medium.f7e77a49.eot);
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url(/static/media/Montserrat-Medium.f7e77a49.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Medium.d194e509.woff) format('woff'),
		url(/static/media/Montserrat-Medium.41940db3.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-ExtraBold.21edc00b.eot);
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
		url(/static/media/Montserrat-ExtraBold.21edc00b.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-ExtraBold.28eef764.woff) format('woff'),
		url(/static/media/Montserrat-ExtraBold.2a3d13e3.ttf) format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-Black.f2e25259.eot);
	src: local('Montserrat Black'), local('Montserrat-Black'),
		url(/static/media/Montserrat-Black.f2e25259.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-Black.c20b9740.woff) format('woff'),
		url(/static/media/Montserrat-Black.59462636.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url(/static/media/Montserrat-ThinItalic.4817b66e.eot);
	src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
		url(/static/media/Montserrat-ThinItalic.4817b66e.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Montserrat-ThinItalic.179f8e8d.woff) format('woff'),
		url(/static/media/Montserrat-ThinItalic.5cd51f31.ttf) format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Regular.09ffa142.eot);
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url(/static/media/Gilroy-Regular.09ffa142.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Regular.11c5b356.woff2) format('woff2'),
        url(/static/media/Gilroy-Regular.2abef08d.woff) format('woff'),
        url(/static/media/Gilroy-Regular.af5b8da1.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ExtraboldItalic.42b21482.eot);
    src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
        url(/static/media/Gilroy-ExtraboldItalic.42b21482.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-ExtraboldItalic.a3053f07.woff2) format('woff2'),
        url(/static/media/Gilroy-ExtraboldItalic.ff7c4c09.woff) format('woff'),
        url(/static/media/Gilroy-ExtraboldItalic.24d67eb0.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Bold.111aa13f.eot);
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url(/static/media/Gilroy-Bold.111aa13f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Bold.62872c8e.woff2) format('woff2'),
        url(/static/media/Gilroy-Bold.3ca17cce.woff) format('woff'),
        url(/static/media/Gilroy-Bold.10b9cd1d.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Black.1fc6680a.eot);
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url(/static/media/Gilroy-Black.1fc6680a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Black.32f3e96b.woff2) format('woff2'),
        url(/static/media/Gilroy-Black.a5e18ce9.woff) format('woff'),
        url(/static/media/Gilroy-Black.24b0461b.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Light.ee921960.eot);
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url(/static/media/Gilroy-Light.ee921960.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Light.085f3ee3.woff2) format('woff2'),
        url(/static/media/Gilroy-Light.679cd801.woff) format('woff'),
        url(/static/media/Gilroy-Light.1111f8b0.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Semibold.3a7f8fd8.eot);
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url(/static/media/Gilroy-Semibold.3a7f8fd8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Semibold.aebaa8b1.woff2) format('woff2'),
        url(/static/media/Gilroy-Semibold.9d2a1b79.woff) format('woff'),
        url(/static/media/Gilroy-Semibold.5805f872.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Medium.f968032c.eot);
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url(/static/media/Gilroy-Medium.f968032c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Medium.d00e876b.woff2) format('woff2'),
        url(/static/media/Gilroy-Medium.7ab5484c.woff) format('woff'),
        url(/static/media/Gilroy-Medium.ef9daf9c.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-MediumItalic.5e3b55f9.eot);
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url(/static/media/Gilroy-MediumItalic.5e3b55f9.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-MediumItalic.451b99f8.woff2) format('woff2'),
        url(/static/media/Gilroy-MediumItalic.9dfbc96b.woff) format('woff'),
        url(/static/media/Gilroy-MediumItalic.8c9ee6ad.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-BlackItalic.fa918152.eot);
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url(/static/media/Gilroy-BlackItalic.fa918152.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-BlackItalic.f9eaa3e3.woff2) format('woff2'),
        url(/static/media/Gilroy-BlackItalic.68bdadbb.woff) format('woff'),
        url(/static/media/Gilroy-BlackItalic.07c948bc.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-UltraLight.1aebb4fa.eot);
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url(/static/media/Gilroy-UltraLight.1aebb4fa.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-UltraLight.16700eaa.woff2) format('woff2'),
        url(/static/media/Gilroy-UltraLight.86a228ff.woff) format('woff'),
        url(/static/media/Gilroy-UltraLight.b81a3837.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-RegularItalic.dd5eabb4.eot);
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url(/static/media/Gilroy-RegularItalic.dd5eabb4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-RegularItalic.9bb45da9.woff2) format('woff2'),
        url(/static/media/Gilroy-RegularItalic.24092ab8.woff) format('woff'),
        url(/static/media/Gilroy-RegularItalic.9b408b83.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-SemiboldItalic.a42e0fdb.eot);
    src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
        url(/static/media/Gilroy-SemiboldItalic.a42e0fdb.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-SemiboldItalic.2138e6ee.woff2) format('woff2'),
        url(/static/media/Gilroy-SemiboldItalic.d9a34427.woff) format('woff'),
        url(/static/media/Gilroy-SemiboldItalic.4a38a309.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-HeavyItalic.fcbbb880.eot);
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url(/static/media/Gilroy-HeavyItalic.fcbbb880.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-HeavyItalic.52ce82b8.woff2) format('woff2'),
        url(/static/media/Gilroy-HeavyItalic.d9bdf85a.woff) format('woff'),
        url(/static/media/Gilroy-HeavyItalic.e8b11870.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Extrabold.6d4b808b.eot);
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
        url(/static/media/Gilroy-Extrabold.6d4b808b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Extrabold.5a9a1fcf.woff2) format('woff2'),
        url(/static/media/Gilroy-Extrabold.bc3a0166.woff) format('woff'),
        url(/static/media/Gilroy-Extrabold.3a1f1d2a.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-BoldItalic.06010399.eot);
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url(/static/media/Gilroy-BoldItalic.06010399.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-BoldItalic.f1609a7d.woff2) format('woff2'),
        url(/static/media/Gilroy-BoldItalic.18d85c61.woff) format('woff'),
        url(/static/media/Gilroy-BoldItalic.5ff93a62.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-UltraLightItalic.22c4641e.eot);
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url(/static/media/Gilroy-UltraLightItalic.22c4641e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-UltraLightItalic.33f9fdd1.woff2) format('woff2'),
        url(/static/media/Gilroy-UltraLightItalic.2569b930.woff) format('woff'),
        url(/static/media/Gilroy-UltraLightItalic.1cb86834.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-LightItalic.41c5b15c.eot);
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url(/static/media/Gilroy-LightItalic.41c5b15c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-LightItalic.0b8cedd7.woff2) format('woff2'),
        url(/static/media/Gilroy-LightItalic.10e81c7d.woff) format('woff'),
        url(/static/media/Gilroy-LightItalic.fdd7be25.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Heavy.1f626f67.eot);
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url(/static/media/Gilroy-Heavy.1f626f67.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Heavy.11d2a876.woff2) format('woff2'),
        url(/static/media/Gilroy-Heavy.791d301c.woff) format('woff'),
        url(/static/media/Gilroy-Heavy.3e6a1bef.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Thin.1466b7c1.eot);
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url(/static/media/Gilroy-Thin.1466b7c1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Thin.d0028c75.woff2) format('woff2'),
        url(/static/media/Gilroy-Thin.4be23ee9.woff) format('woff'),
        url(/static/media/Gilroy-Thin.71000d5f.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ThinItalic.ed0dbe24.eot);
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
        url(/static/media/Gilroy-ThinItalic.ed0dbe24.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-ThinItalic.21faaec1.woff2) format('woff2'),
        url(/static/media/Gilroy-ThinItalic.bd8e57f9.woff) format('woff'),
        url(/static/media/Gilroy-ThinItalic.cb76555d.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}


.footer_wrapper__1zLWO{
    min-height: 40px;
    /*background: #A2D9F7;*/
    background: #EDEDEE;
    position: relative;
	left: 0;
	bottom: 0;
	width: 100%;
}
.footer_wrapper_links__3vnTK{
    display: inline-block;
    height: 100%;
}

.footer_text__O1moW{
    /*padding-top: 10px;*/
    text-align: center;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 40px;

    color: #000000;
    /*font-family: 'Montserrat';*/
    /*font-style: normal;*/
    /*font-weight: 300;*/
    /*font-size: 16px;*/
    /*line-height: 18px;*/
    /*width: 100%;*/
    /*text-align: center;*/

    /*color: #000000;*/
}
.footer_resource_link__3Invb,
.footer_author__3PWA2,
.footer_back_call__3Lkjg{
    display: inline-block;
    position: relative;
    width: 160px;
    height: 30px;

    text-align: center;
    background-color: #A2D9F7;
    border: 3px solid #285CA6;
    box-sizing: border-box;
    border-radius: 20px;

    color: #285CA6;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;

    line-height: 25px;

}

.footer_resource_link__3Invb{
    text-decoration: none;
    width: 200px;
}

@media(max-width:981px) {
    .footer_resource_link__3Invb{
        display: none;
       position: fixed;
        top:0;
        left: 0;
    }
}

@media(max-width:800px){
    .footer_wrapper_links__3vnTK{

        display: block;
        width: 100%;
        text-align: center;
        height: auto;

    }

    .footer_wrapper__1zLWO{
        padding: 5px 0;

    }

    .footer_text__O1moW{
        margin: 8px 0;
        display: block;
        position: static;
        width: 100%;
        text-align: center;
        padding: 0;
        height: auto;
        float: none;
    }

}


/*@media(max-width:370px) {*/
/*    .text{*/
/*        display: inline-block;*/
/*    }*/

/*}*/
.addUser_wrapper__1R_W9{

}
.addUser_new_user__yduuh{
    background-color: rgb(212, 221, 250);
    width: 96%;
    margin: 10px auto;
    padding: 10px;
}
.addUser_wrapper_user_list__R9U5Q{
    width: 96%;
    margin: auto;
}
.addUser_title_list__1ZIdp{
    margin-bottom: 5px;
    padding: 10px;
}
.addUser_rows_users__I83cn{
   
}
.addUser_tr_new_user__2nugb {
    width: 90%;
}

.addUser_qr__3mciz{
    width: 100px;
}

.addUser_generateTable__3UIj6{
    width: 100%;
}
.addUser_td_name__1Iwql{

    display:inline-block;
}
.addUser_td_password__3TJYV{

    display:inline-block;
}
.addUser_td_token__125if{
    width: 800px;

}
.addUser_td_qr__2B3Oi{
    width: 40px;
    display:inline-block;
}
.addUser_backButton__eF6k1{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}

.addUser_btn_save_new_user__1SkYo{
    display: inline-block;
    margin: 10px;
    background-color: rgb(180, 255, 177);

    min-width: 100px;

    text-align: center;
    border:2px solid rgb(19, 112, 0);
    border-radius: 20px;
    padding: 2px;
}
.addUser_errorMessage__YTbsF{
    color:red;
}


.addUser_btn_gen_url__2E2cq{
    padding: 5px;
    border:1px solid orange;
    background-color: rgb(255, 241, 214);
    border-radius: 10px;
}

.addUser_btn_gen_url__2E2cq:hover,
.addUser_backButton__eF6k1:hover,
.addUser_btn_save_new_user__1SkYo:hover{
    cursor:pointer;
}

.addUser_add_user_input__tBm2f{
    border:1px solid blue;
    border-radius: 10px;
    padding: 4px;
    margin-right: 10px;
    margin-top: 10px;

}

.userList_wrapper__2OSRm{
    width: 300px;
    border: 2px solid silver;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5px;
}
.userList_form_update_user__3ROte{
    border: 1px solid silver;
    border-radius: 10px;
    padding: 10px;
}

.userList_btn_save__xJvIz{
    display: inline-block;
    width: 100px;
    text-align: center;
    border: 1px solid green;
    border-radius: 10px;
    background-color: rgb(188, 253, 188);
    padding: 5px;
    margin-top: 10px;
}


.userList_btn_update_password__1usWR{
    width: 140px;
    text-align: center;
    border: 1px solid orange;
    border-radius: 10px;
    background-color: rgb(255, 255, 171);
    padding: 5px;
    margin-top: 10px;
}

.userList_btn_del__1EQOX{
    display: inline-block;
    margin-left: 40px;
    width: 100px;
    text-align: center;
    border: 1px solid red;
    border-radius: 10px;
    background-color: rgb(250, 139, 139);
    padding: 5px;
    margin-top: 10px;
}

.userList_inputPass__2Smn-{
    display: block;
    margin-top: 10px;
}
.userList_btn_del__1EQOX:hover,
.userList_btn_update_password__1usWR:hover,
.userList_btn_save__xJvIz:hover{
    cursor: pointer;
}
.statisticPage_wrapper__3ZbKD{
    margin: 10px auto;
    width: 40%;
}
.statisticPage_title_card__2s0EV{
    padding: 5px 10px;

    background-color: rgb(169, 169, 252);
    margin-top: 10px;
}
.statisticPage_org_name__2k7HP{
    display: inline-block;
    margin-left: 10px;
}
.statisticPage_card_progress__TPQm_{
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    /*border-bottom: 1px solid #5c5c5c;*/
}

.statisticPage_item_progress__1fg5s{
    padding:10px;
    border:1px solid black;

}

.statisticPage_td_title__1Gvox{
    min-width: 200px;
    padding-left: 7px;
}

.statisticPage_date_row__3NoE5{
    border-bottom: 1px solid #7f7f7f;
}

.statisticPage_row_card__EaXIo{
    border: 1px solid #848484;
}

.statisticPage_backButton__2VVaM{
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    border: 1px solid rgb(169, 169, 252) ;
    border-radius: 10px;
    width: 200px;
    text-align: center;
    background-color:  rgb(237, 237, 254);
}

.statisticPage_enters_table__M9ibp{
    border: 1px solid black;
    border-collapse: collapse;
}
.statisticPage_enters_table__M9ibp td{
    border: 1px solid black;
}
.support_card_wrapper__2QCr1{
    height: auto;
}
.support_wrapper__YgdPl{
    height: auto;
    margin: 10px auto;
    min-width: 300px;
    width: 60%;
}

.support_card__1ykV2{
    border:1px solid blue;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 10px;
    height: auto;
    background-color: #e4edfc6b;
}

.support_worker_information__x72Jk{
   padding: 10px 15px;
    background-color: rgba(192, 192, 192, 0.18);
    border-radius: 20px;
}

.support_message__3-kMP{
    color:red;
}
.support_title_card__1-tp6{
    margin: 10px 0 3px 0;
}

.support_btn_del__3NDts{
    display: inline-block;
    width: 120px;
    margin-bottom:10px;
    border:1px solid red;
    border-radius: 20px;
    background-color: rgb(255, 193, 193);
    padding: 5px;
   cursor: pointer;
}

.support_btn_update__3BfwE{
    display: inline-block;
    width: 120px;
    margin-bottom:10px;
    border:1px solid orange;
    border-radius: 20px;
    background-color: rgb(255, 225, 170);
    padding: 5px;
    cursor: pointer;
    margin-right: 40px;
}

.support_backButton__3inom{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}

.support_textbox_data_post__2g8uK{
    width: 95%;
    padding: 5px;
    font-size: 16px;
    margin: auto;
}

.support_text_post__2F1sc{
   
    width: 95%;
    padding: 5px;
    font-size: 16px;
}

.support_wrapper_checkbox__2EtJA{
    margin: 10px;
}

.support_wrapper_checkbox__2EtJA input{
    padding:10px;
    width:19px;
     height:19px;
}
.category_card_wrapper__1YuSt{
    width: 400px;
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #285CA6;
}

.category_card_not_post__2KvfL{
    padding: 3px;
    background-color: #f84646;
    border-radius: 20px;
    color: aliceblue;
    text-align: center;
}

.category_card_imgQuiz__pPhM8{

}
.SettingsGamePage_input_count_team__37EX8{

    text-align: center;

}
.SettingsGamePage_wrapper__1n-hG{
    padding: 20px;
    margin-top: 10px;
}
.SettingsGamePage_team_box__2qwx_{
    display: inline-block;
    padding: 10px;
    margin: 10px;
    border: 3px solid #FF0000;
}


.SettingsGamePage_btn_start__3jDXl{
    margin:10px auto 0;
    width: 150px;
    color:white;
    height: 30px;
    line-height: 30px;
    font-family: Montserrat;
    background-color: #FF0000;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;

}
.AddRoom_wrapper__3k-hh{
    width: 70%;
    border: 3px solid #FF0000;
    border-radius: 20px;
    margin: 40px auto;
    padding: 10px;
    font-family: Gilroy;
}
.AddRoom_wrapper__3k-hh input{
    display: block;
    max-width: 300px;
    width: 90%;
    padding: 5px 10px;
    border: 3px solid #FF0000;
    border-radius: 20px;
    margin: 20px auto;
}
.AddRoom_wrapper__3k-hh div{
    text-align: center;
    display: block;
    margin: 10px auto;
}
.AddRoom_btn_add_team__HbjgN{
    display: inline-block;
    text-align: center;
    width: 30px;
    line-height: 30px;
    background-color: white;
    border: 2px solid silver;
    cursor: pointer;
}
.AddRoom_btn_save__2fZQY{
    display: block;
    width: 130px;
    border: 3px solid #FF0000;
    text-align: center;
    margin: 20px;
    background-color: #FF0000 ;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
a{
    text-decoration: none;
}
.RoomCard_wrapper__2dFu5{
    display: inline-block;
    padding: 10px;
    margin: 10px;
    border:3px solid #FF0000;
    border-radius: 20px;
    font-family: Gilroy;
}

.RoomCard_title__d9SEV{
    margin-bottom: 10px;
    font-weight: 800;
    color: #FF0000
}
.RoomCard_btn_update__2LZsw,
.RoomCard_btn_remove__1-g-0,
.RoomCard_btn_start__14h6f{
    display: inline-block;
    text-decoration: none;
    width: 130px;
    border: 3px solid #FF0000;
    text-align: center;
    margin: 5px;
    background-color: #fff ;
    color: #FF0000;
    border-radius: 20px;
    cursor: pointer;
}

.RoomCard_btn_start__14h6f{
    display: block;
    background-color: #FF0000 ;
    color: #fff;
}

.RoomBox_wrapper__1LmKj{
    display: inline-block;

    margin: 20px;
    border:3px solid #FF0000;
    font-family: Gilroy;
    text-align: center;
    border-radius: 20px;
    font-weight: 800;
    min-width: 220px;
}

.RoomBox_title__1uKDN{
    font-size: 28px;
    color:#FF0000;
    margin: 12px auto 15px auto;
    text-align: center;
}

.RoomBox_team_name__ug28G{
    font-size: 28px;
    color:#FF0000;
    margin: 10px;
    text-align: center;
}
.PreviewRoom_wrapper__2wpIj{
    margin-top: 20px;
    padding-bottom: 30px;
}

.PreviewRoom_title_quiz__sktDh{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */


    color: #FF0000;
    margin:20px 30px 0 30px;
}

.PreviewRoom_btn_start__3dxrj{

    border:3px solid #FF0000;
    text-align: center;
    font-family: Gilroy;
    text-align: center;
    color: white;
    background-color:#FF0000 ;
    height: 49px;
    margin: 30px auto 0 auto;
    cursor:pointer;
    font-size: 40px;
    line-height: 49px;
    border-radius: 47px;
    width: 320px;
}

.PreviewRoom_cards_wrapper__FJrxh{
    text-align: center;
}

@media (max-width: 770px) {

    .PreviewRoom_wrapper__2wpIj{
        margin-top: 60px;
    }
}

