.form{
    max-width: 1000px;
    min-width: 100px;
    margin: 20px auto;
}

.title{
    padding: 3px;
    margin: 5px;
}
.label_select{
    margin-bottom: 4px;
}

.select_quiz{
    display: block; 
font-size: 16px; 
font-family: sans-serif; 
font-weight: 700; 
color: #444; 
line-height: 1.3; 
padding: .6em 1.4em .5em .8em; 
width:300px; 
max-width: 100%; 
box-sizing: border-box; 
margin: 0; 
border: 1px solid #aaa;
 box-shadow: 0 1px 0 1px rgba(0,0,0,.04); 
border-radius: .5em;
 -moz-appearance: none;
 -webkit-appearance: none;
 appearance: none;
 background-color: #fff; 
background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%); 
background-repeat: no-repeat, repeat;
background-position: right .7em top 50%, 0 0;
background-size: .65em auto, 100%; 
}
.select_quiz::-ms-expand{ display: none; } 
.select_quiz:hover{ border-color: #888; } 
.select_quiz:focus{
    border-color: #aaa; 
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring; 
   color: #222;
    outline: none; 
}

.select_quiz option{
    font-weight:normal;
    background-position: left .7em top 50%, 0 0; 
    padding: .6em .8em .5em 1.4em; 
}

.text{
    border-bottom: 1px solid #ffc6c2;
    border-top: 1px solid #bfbfbf;
    padding: 3px;
    margin: 5px;
}

.noActive{
    padding: 3px;
    text-align: center;
    margin: 5px;
    background-color: rgba(255, 206, 206, 0.69);
    width: 200px;
    border: 1px solid red;
    border-radius: 20px;
}

.addButton{
    height: 35px;
    width: 300px;
    margin: 5px;
    border:1px solid green;
    border-radius:20px;
    background-color: rgb(142, 243, 142);
    text-align: center;
    padding-top: 6px;
}

.addButton:hover{
    cursor: pointer;
}

.wrapper{
    border: 1px solid rgba(59, 95, 147, 0.68);
    background-color: rgba(219, 224, 234, 0.44);
    padding: 10px;
    border-radius: 10px;
    margin: 5px;

}

.select{
    padding: 10px;
    background-color: #eef5fc;
    border-radius: 10px;
}

.btn_update{
    display: inline-block;
    padding: 5px;
    text-align: center;
    border: 1px solid orange;
    width: 100px;
    background-color: rgb(248, 220, 170);
    border-radius: 20px;
}

.btn_del{
    display: inline-block;
    padding: 5px;
    text-align: center;
    border: 1px solid red;
    width: 100px;
    background-color: rgb(255, 176, 176);
    border-radius: 20px;
    margin-left: 40px;
    
}

.btn_del:hover,
.btn_update:hover{
    cursor: pointer;
}

.backButton{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}


.backButton{
    display: block;
    margin: 20px 20px 10px 20px;
    text-decoration: none;
    color:#285CA6;;
    background-color: silver;
    width: 100px;
    text-align: center;
    border:2px solid rgb(145, 145, 145);
    border-radius: 20px;
    padding: 2px;
}