.wrapper{
    width: 300px;
    border: 2px solid silver;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5px;
}
.form_update_user{
    border: 1px solid silver;
    border-radius: 10px;
    padding: 10px;
}

.btn_save{
    display: inline-block;
    width: 100px;
    text-align: center;
    border: 1px solid green;
    border-radius: 10px;
    background-color: rgb(188, 253, 188);
    padding: 5px;
    margin-top: 10px;
}


.btn_update_password{
    width: 140px;
    text-align: center;
    border: 1px solid orange;
    border-radius: 10px;
    background-color: rgb(255, 255, 171);
    padding: 5px;
    margin-top: 10px;
}

.btn_del{
    display: inline-block;
    margin-left: 40px;
    width: 100px;
    text-align: center;
    border: 1px solid red;
    border-radius: 10px;
    background-color: rgb(250, 139, 139);
    padding: 5px;
    margin-top: 10px;
}

.inputPass{
    display: block;
    margin-top: 10px;
}
.btn_del:hover,
.btn_update_password:hover,
.btn_save:hover{
    cursor: pointer;
}